addEventListener("direct-upload:initialize", (event) => {
  const { target, detail } = event;
  const { id, file } = detail;
  target.insertAdjacentHTML(
    "beforebegin",
    `
        <div class="direct-upload direct-upload--pending progress mb-1" id="direct-upload-${id}">
        <div id="direct-upload-progress-${id}" class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" style="width: 0%"></div>
        </div>
  
`
  );
});

addEventListener("direct-upload:start", (event) => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove("direct-upload--pending");
});

addEventListener("direct-upload:progress", (event) => {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(
    `direct-upload-progress-${id}`
  );
  progressElement.style.width = `${progress}%`;
});

addEventListener("direct-upload:error", (event) => {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-progress-${id}`);
  element.classList.add("bg-danger");
  alert("Envoi impossible !");
  var selectors = [Rails.linkDisableSelector, Rails.formEnableSelector].join(
    ", "
  );
  $(selectors).each(function () {
    Rails.enableElement(this);
  });
  progress = $(`#direct-upload-${id}`);
  progress.hide(400);
});

addEventListener("direct-upload:end", (event) => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--complete");
});
